<template>
  <div>
    <b-message v-if="inschrijving.errorMessage" type="is-warning">
      {{ inschrijving.errorMessage }}
    </b-message>

    <b-field label="Deelnemen als">
      <b-input disabled :value="inschrijving.type"> </b-input>
    </b-field>

    <b-field label="Aantal deelnemers">
      <b-input disabled :value="inschrijving.aantalDeelnemers"> </b-input>
    </b-field>

    <b-field
      label="Slapen binnen"
      :type="inschrijving.akkoord === false ? 'is-danger' : undefined"
      :message="
        inschrijving.akkoord === false
          ? 'Jullie kunnen wel mee doen maar er waren helaas geen slaapplaatsen meer.'
          : undefined
      "
    >
      <b-checkbox disabled :value="inschrijving.binnenSlapen"> </b-checkbox>
    </b-field>

    <b-field
      label="Groepsnaam"
      :type="messageNaarType(naamMessage)"
      :message="naamMessage"
    >
      <b-input v-model="groep.naam" @blur="() => (naamHit = true)"> </b-input>
    </b-field>

    <b-field
      label="Groepswachtwoord"
      :type="messageNaarType(wachtwoordMessage)"
      :message="wachtwoordMessage"
    >
      <b-input v-model="groep.wachtwoord" @blur="() => (wachtwoordHit = true)">
      </b-input>
    </b-field>

    <b-field
      label="Postcode"
      :type="messageNaarType(postcodeMessage)"
      :message="postcodeMessage"
    >
      <b-input v-model="groep.postcode" @blur="() => (postcodeHit = true)">
      </b-input>
    </b-field>

    <b-field
      label="Plaats"
      :type="messageNaarType(plaatsMessage)"
      :message="plaatsMessage"
    >
      <b-input v-model="groep.plaats" @blur="() => (plaatsHit = true)">
      </b-input>
    </b-field>

    <b-field
      label="Straat"
      :type="messageNaarType(straatMessage)"
      :message="straatMessage"
    >
      <b-input v-model="groep.straat" @blur="() => (straatHit = true)">
      </b-input>
    </b-field>

    <b-field
      label="Huisnummer"
      :type="messageNaarType(huisnummerMessage)"
      :message="huisnummerMessage"
    >
      <b-input v-model="groep.huisnummer" @blur="() => (huisnummerHit = true)">
      </b-input>
    </b-field>

    <b-field
      label="Iban"
      :type="messageNaarType(ibanMessage)"
      :message="ibanMessage"
    >
      <b-input
        v-model="groep.iban"
        :disabled="financieleVeldenDisabled"
        @blur="() => (ibanHit = true)"
      >
      </b-input>
    </b-field>

    <b-field
      label="Iban tenaamstelling"
      :type="messageNaarType(tenaamstellingMessage)"
      :message="tenaamstellingMessage"
    >
      <b-input
        v-model="groep.tenaamstelling"
        :disabled="financieleVeldenDisabled"
        @blur="() => (tenaamstellingHit = true)"
      >
      </b-input>
    </b-field>

    <div class="button__holder">
      <b-button type="is-primary" @click="opslaan">Opslaan</b-button>
    </div>
  </div>
</template>
<script>
import { BMessage } from "buefy/src/components/message";
import { BField } from "buefy/src/components/field";
import { BInput } from "buefy/src/components/input";
import { BButton } from "buefy/src/components/button";

import { messageNaarType } from "@/utils/helpers";

export default {
  components: {
    BMessage,
    BField,
    BInput,
    BButton,
  },
  props: {
    value: {
      required: false,
      default: () => ({
        naam: "",
        wachtwoord: "",
        postcode: "",
        plaats: "",
        straat: "",
        huisnummer: "",
        iban: "",
        tenaamstelling: "",
      }),
    },
    financieleVeldenDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validationHit: false,
      naamHit: false,
      wachtwoordHit: false,
      postcodeHit: false,
      plaatsHit: false,
      straatHit: false,
      huisnummerHit: false,
      ibanHit: false,
      tenaamstellingHit: false,
      groep: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.groep = newValue;
      },
    },
  },
  computed: {
    inschrijving() {
      return this.$store.state.inschrijving;
    },
    naamMessage() {
      if (
        (this.validationHit || this.naamHit) &&
        this.groep.naam.trim().length === 0
      ) {
        return "Naam mag niet leeg zijn";
      }
      return undefined;
    },
    wachtwoordMessage() {
      if (
        (this.validationHit || this.wachtwoordHit) &&
        (this.groep.wachtwoord.trim().length < 4) |
          (this.groep.wachtwoord.trim().length > 14)
      ) {
        return "Wachtwoord moet tussen de 4 en 14 tekens lang zijn";
      }
      return undefined;
    },
    postcodeMessage() {
      if (
        (this.validationHit || this.postcodeHit) &&
        !/^[1-9][0-9]{3}\s?(?!sa|sd|ss)[a-zA-Z]{2}$/.exec(this.groep.postcode)
      ) {
        return "Dit is geen geldige postcode";
      }
      return undefined;
    },
    plaatsMessage() {
      if (
        (this.validationHit || this.plaatsHit) &&
        this.groep.plaats.trim().length === 0
      ) {
        return "Plaats mag niet leeg zijn";
      }
      return undefined;
    },
    straatMessage() {
      if (
        (this.validationHit || this.straatHit) &&
        this.groep.straat.trim().length === 0
      ) {
        return "Straat mag niet leeg zijn";
      }
      return undefined;
    },
    huisnummerMessage() {
      if (
        (this.validationHit || this.huisnummerHit) &&
        this.groep.huisnummer.trim().length === 0
      ) {
        return "Huisnummer mag niet leeg zijn";
      }
      return undefined;
    },
    ibanMessage() {
      if (
        (this.validationHit || this.ibanHit) &&
        !/^(nl|NL|nL|Nl)\d{2}[A-Za-z]{4}\d{10}$/.exec(this.groep.iban)
      ) {
        return "Dit is geen geldige IBAN";
      }
      return undefined;
    },
    tenaamstellingMessage() {
      if (
        (this.validationHit || this.tenaamstellingHit) &&
        this.groep.tenaamstelling.trim().length === 0
      ) {
        return "Tenaamstelling mag niet leeg zijn";
      }
      return undefined;
    },
  },
  methods: {
    messageNaarType,
    opslaan() {
      this.validationHit = true;

      if (
        this.naamMessage ||
        this.wachtwoordMessage ||
        this.postcodeMessage ||
        this.plaatsMessage ||
        this.straatMessage ||
        this.huisnummerMessage ||
        this.ibanMessage ||
        this.tenaamstellingMessage
      ) {
        return;
      }

      this.$emit("input", this.groep);
    },
  },
};
</script>
