<template>
  <div class="container">
    <h1 class="title">Inschrijving</h1>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-content">
            <p v-if="inschrijvingStatus === 'NietGestart'">
              Inschrijving is nog gesloten
            </p>
            <p v-else-if="inschrijvingStatus === 'Gesloten'">
              Inschrijving is gesloten
            </p>
            <p v-else-if="inschrijvingStatus === 'InIemandsReservering'">
              Je zit bij iemand in de reservering, wanneer deze is afgerond kun
              je je gegevens bewerken
            </p>
            <p v-else-if="inschrijvingStatus === 'InTeam'">
              <template v-if="inschrijving.groep">
                <b-tabs>
                  <b-tab-item label="Groep">
                    <groep
                      :value="inschrijving.groep"
                      @input="updateInTeamGroep"
                      :financiele-velden-disabled="
                        !inschrijving.financieleChangeMogelijk
                      "
                    />
                  </b-tab-item>
                  <b-tab-item label="Deelnemer">
                    <deelnemer
                      :value="inschrijving.deelnemer"
                      @input="updateInTeamDeelnemer"
                      :email-disabled="true"
                      :financiele-velden-disabled="
                        !inschrijving.financieleChangeMogelijk
                      "
                    />
                  </b-tab-item>
                </b-tabs>
              </template>
              <template v-else>
                <deelnemer
                  :value="inschrijving.deelnemer"
                  @input="updateInteamDeelnemer"
                  :email-disabled="true"
                  :financiele-velden-disabled="
                    !inschrijving.financieleChangeMogelijk
                  "
                />
              </template>
            </p>
            <b-steps
              :value="activeTab"
              :has-navigation="false"
              v-else
              @input="onTabUpdate"
            >
              <b-step-item
                :clickable="inschrijvingStatus === 'Open'"
                label="Inschrijven"
                icon="playlist-edit"
              >
                <reservering />
              </b-step-item>
              <b-step-item
                :visible="inschrijvingStatus === 'OpWachtlijst'"
                label="Wachtlijst"
                icon="account-clock"
              >
                <p>De inschrijving is vol, je staat nu op de wachtlijst</p>
                <!--todo verwijderen van wachtlijst -->
              </b-step-item>
              <b-step-item
                :visible="inschrijvingStatus === 'Gereserveerd'"
                label="Groep"
                icon="account-group"
              >
                <groep
                  :value="inschrijving.groep"
                  @input="updateGroep"
                  :financiele-velden-disabled="
                    !inschrijving.financieleChangeMogelijk
                  "
                />
              </b-step-item>
              <template v-for="(deelnemer, index) in inschrijving.deelnemers">
                <b-step-item
                  :visible="inschrijvingStatus === 'Gereserveerd'"
                  label="Deelnemer"
                  icon="account"
                  :key="index"
                >
                  <deelnemer
                    :value="deelnemer"
                    @input="(d) => updateDeelnemer(index, d)"
                    :email-disabled="index === 0"
                    :financiele-velden-disabled="
                      !inschrijving.financieleChangeMogelijk
                    "
                  />
                </b-step-item>
              </template>
              <b-step-item
                :visible="inschrijvingStatus === 'Gereserveerd'"
                label="Afronden"
                icon="check-all"
              >
                <b-message v-if="inschrijving.errorMessage" type="is-warning">
                  {{ inschrijving.errorMessage }}
                </b-message>
                <p>
                  Klik hieronder om de inschrijving af te ronden, vanaf dat
                  moment kunnen de andere deelnemers een account aanmaken en hun
                  eigen gegevens bewerken.
                </p>
                <b-button type="is-primary" @click="afronden"
                  >Afronden</b-button
                >
              </b-step-item>
            </b-steps>
            <b-loading :active="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSteps, BStepItem } from "buefy/src/components/steps";
import { BLoading } from "buefy/src/components/loading";

import Reservering from "@/components/inschrijving/Reservering.vue";
import Groep from "@/components/inschrijving/Groep.vue";
import Deelnemer from "@/components/inschrijving/Deelnemer.vue";

const eersteDeelnemerTabIndex = 3;

export default {
  components: {
    BSteps,
    BStepItem,
    BLoading,
    Reservering,
    Groep,
    Deelnemer,
  },
  data: () => ({
    isLoading: false,
    groepen: [],
    zoekenTerm: "",
    showCreateGroepModal: false,
    maakGroepValidatie: undefined,
  }),
  async mounted() {
    await this.$store.dispatch("updateInschrijvingStatus");
  },
  computed: {
    inschrijving() {
      return this.$store.state.inschrijving;
    },
    loading() {
      return this.inschrijving.loading;
    },
    inschrijvingStatus() {
      return this.inschrijving.status;
    },
    activeTab() {
      switch (this.inschrijvingStatus) {
        case "Open":
          return 0;
        case "OpWachtlijst":
          return 1;
        case "Gereserveerd": {
          return eersteDeelnemerTabIndex + this.inschrijving.deelnemerIndex;
        }
        default:
          return 0;
      }
    },
  },
  methods: {
    async updateGroep(groep) {
      await this.$store.dispatch("updateGroep", groep);
    },
    async updateDeelnemer(index, deelnemer) {
      await this.$store.dispatch("updateDeelnemer", {
        deelnemerIndex: index,
        deelnemer,
      });
    },
    async afronden() {
      await this.$store.dispatch("inschrijvingAfronden");
    },
    onTabUpdate(index) {
      var deelnemerIndex = index - eersteDeelnemerTabIndex;
      if (deelnemerIndex >= -1) {
        this.$store.commit("updateInschrijving", { deelnemerIndex });
      }
    },
    async updateInTeamGroep(team) {
      await this.$store.dispatch("updateInTeamGroep", team);
    },
    async updateInTeamDeelnemer(deelnemer) {
      await this.$store.dispatch("updateInTeamDeelnemer", deelnemer);
    },
  },
};
</script>
