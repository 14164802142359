import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/stores";
// import Spel from "@/views/Spel.vue";

import Home from "@/views/Home.vue";

import Inschrijving from "@/views/Inschrijving.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/inschrijving",
    name: "Inschrijving",
    meta: {
      requiresLogin: true,
    },
    component: Inschrijving,
  },
  {
    path: "/scores/:editie",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "scoreviewer" */ "../views/scoreviewer/Overzicht.vue"
      ),
  },
  {
    path: "/scores/:editie/:groepId",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "scoreviewer" */ "../views/scoreviewer/Groep.vue"
      ),
  },
  // {
  //   path: "/spel",
  //   name: "Spel",
  //   component: Spel,
  // },
  {
    path: "/hoofdkwartier/groepen",
    meta: {
      requiresToegangTot: "ViewSpelAssets",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/Groepen.vue"
      ),
  },
  {
    path: "/hoofdkwartier/groepen/:groepId",
    props: true,
    meta: {
      requiresToegangTot: "ViewSpelAssets",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/Groep.vue"
      ),
  },
  {
    path: "/hoofdkwartier/posten",
    meta: {
      requiresToegangTot: "ViewSpelAssets",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/Posten.vue"
      ),
  },
  {
    path: "/hoofdkwartier/posten/:postId",
    props: true,
    meta: {
      requiresToegangTot: "ViewSpelAssets",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/Post.vue"
      ),
  },
  {
    path: "/hoofdkwartier/scanner",
    meta: {
      requiresOpenInschrijving: true,
      requiresToegangTot: "ViewSpelAssets",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/BarcodeScanner.vue"
      ),
  },
  {
    path: "/hoofdkwartier/radar",
    meta: {
      requiresToegangTot: "ViewSpelAssets",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/Radar.vue"
      ),
  },
  {
    path: "/hoofdkwartier/seekerradar",
    meta: {
      requiresToegangTot: ["ViewRadar", "ViewSpelAssets"],
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/SeekerRadar.vue"
      ),
  },
  {
    path: "/hoofdkwartier/reporting",
    meta: {
      requiresToegangTot: "ViewSpelAssets",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/Reporting.vue"
      ),
  },
  {
    path: "/hoofdkwartier/scores",
    meta: {
      requiresToegangTot: "ViewHighscore",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/Highscores.vue"
      ),
  },
  {
    path: "/hoofdkwartier/bosgebieden",
    meta: {
      requiresToegangTot: "ViewSpelAssets",
    },
    component: () =>
      import(
        /* webpackChunkName: "hoofdkwartier" */ "../views/hoofdkwartier/BosGebieden.vue"
      ),
  },
  {
    path: "/beheer/reserveringen",
    meta: {
      requiresOpenInschrijving: true,
      requiresToegangTot: "EditGroeps",
    },
    component: () =>
      import(
        /* webpackChunkName: "beheer" */ "../views/beheer/Reserveringen.vue"
      ),
  },
  {
    path: "/beheer/reserveringen/:groepId",
    props: true,
    meta: {
      requiresOpenInschrijving: true,
      requiresToegangTot: "EditGroeps",
    },
    component: () =>
      import(/* webpackChunkName: "beheer" */ "../views/beheer/EditGroep.vue"),
  },
  {
    path: "/beheer/groepen",
    meta: {
      requiresOpenInschrijving: false,
      requiresToegangTot: "EditGroeps",
    },
    component: () =>
      import(/* webpackChunkName: "beheer" */ "../views/beheer/Groepen.vue"),
  },
  {
    path: "/beheer/posten",
    meta: {
      requiresToegangTot: "ManagePosten",
    },
    component: () =>
      import(/* webpackChunkName: "beheer" */ "../views/beheer/Posten.vue"),
  },
  {
    path: "/beheer/settings",
    meta: {
      requiresOpenInschrijving: true,
      requiresToegangTot: "ManageSettings",
    },
    component: () =>
      import(/* webpackChunkName: "beheer" */ "../views/beheer/Settings.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(
      (record) => record.meta.requiresOpenInschrijving === true
    ) &&
    !store.state.userInfo.openInschrijving
  ) {
    next("/");
    return;
  }
  if (
    to.matched.some(
      (record) => record.meta.requiresOpenInschrijving === false
    ) &&
    store.state.userInfo.openInschrijving
  ) {
    next("/");
    return;
  }
  if (
    (to.matched.some((record) => record.meta.requiresLogin) ||
      to.matched.some((record) => record.meta.requiresToegangTot)) &&
    !store.state.userInfo.isLoggedIn
  ) {
    location.href = "/identity/account/login";
    return;
  }

  const expectedToegangTot = to.matched
    .filter((record) => record.meta.requiresToegangTot)
    .map((record) => record.meta.requiresToegangTot);
  if (
    !expectedToegangTot.every((e) =>
      Array.isArray(e)
        ? e.some((i) => store.state.userInfo.toegangTot.includes(i))
        : store.state.userInfo.toegangTot.includes(e)
    )
  ) {
    next("/");
    return;
  } else {
    next();
  }
});

export default router;
