<template>
  <div>
    <b-message type="is-danger" v-if="errorMessage">{{
      errorMessage
    }}</b-message>
    <b-field
      label="Aantal deelnemers"
      :type="messageNaarType(aantalDeelnemersMessage)"
      :message="aantalDeelnemersMessage"
    >
      <b-select
        :value="reservering.aantalDeelnemers"
        @input="updateAantalDeelnemers"
        expanded
      >
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
      </b-select>
    </b-field>
    <b-field
      label="Deelnemen als"
      :type="messageNaarType(typeMessage)"
      :message="typeMessage"
    >
      <b-select :value="reservering.type" @input="updateType" expanded>
        <option value="Hiker">Hiker</option>
        <option value="Seeker">Seeker</option>
      </b-select>
    </b-field>
    <b-field>
      <b-checkbox v-model="reservering.binnenSlapen">Binnenslapen</b-checkbox>
    </b-field>
    <b-button class="button is-primary" @click="reserveren">Verder</b-button>
  </div>
</template>
<script>
import { messageNaarType } from "@/utils/helpers";
import { BMessage } from "buefy/src/components/message";

export default {
  components: {
    BMessage,
  },
  data: function () {
    return {
      reservering: {
        aantalDeelnemers: "",
        type: "",
        binnenSlapen: false,
      },
      validationHit: false,
      aantalDeelnemersHit: false,
      typeHit: false,
    };
  },
  computed: {
    aantalDeelnemersMessage() {
      if (
        (this.aantalDeelnemersHit || this.validationHit) &&
        (!this.reservering.aantalDeelnemers ||
          this.reservering.aantalDeelnemersMessage < 2 ||
          this.reservering.aantalDeelnemers > 6)
      ) {
        return "Je moet minimaal 2 en maximaal 6 deelnemers hebben";
      }
      return undefined;
    },
    typeMessage() {
      if (
        (this.typeHit || this.validationHit) &&
        this.reservering.type !== "Hiker" &&
        this.reservering.type !== "Seeker"
      ) {
        return "Meedoen kan alleen als hiker of seeker";
      }
      return undefined;
    },
    errorMessage() {
      return this.$store.errorMessage;
    },
  },
  methods: {
    messageNaarType,
    updateAantalDeelnemers(value) {
      this.aantalDeelnemersHit = true;
      this.reservering.aantalDeelnemers = value;
    },
    updateType(value) {
      this.typeHit = true;
      this.reservering.type = value;
    },
    async reserveren() {
      this.validationHit = true;

      if (this.aantalDeelnemersMessage || this.typeMessage) {
        return;
      }

      await this.$store.dispatch("plaatsReservering", this.reservering);
    },
  },
};
</script>
