const dateTimeFormatter = new Intl.DateTimeFormat("Nl-nl", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
});

const dateFormatter = new Intl.DateTimeFormat("Nl-nl", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

export function formatDateTime(time) {
  return dateTimeFormatter.format(time);
}

export function formatDate(date) {
  return dateFormatter.format(date);
}
