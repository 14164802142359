import Vue from "vue";
import Vuex from "vuex";
import * as InschrijvingenService from "@/services/inschrijving.service";

Vue.use(Vuex);

const getDefaultValuesDeelnemer = () =>
  Object.assign(
    {},
    {
      voornaam: "",
      tussenvoegsels: "",
      achternaam: "",
      geboortedatum: undefined,
      email: "",
      scoutId: "",
      telefoonnummer: "",
      vegatarisch: false,
      shirt: "None",
      barkaarten: 0,
    }
  );

const store = new Vuex.Store({
  state: {
    userInfo: JSON.parse(document.body.getAttribute("data-configuration")),
    inschrijving: {
      loading: false,
      akkoord: true,
      financieleChangeMogelijk: false,
      status: "NietGestart",
      errorMessage: undefined,
    },
  },
  mutations: {
    updateInschrijving(state, inschrijving) {
      state.inschrijving = { ...state.inschrijving, ...inschrijving };
    },
  },
  actions: {
    async updateInschrijvingStatus({ commit }) {
      commit("updateInschrijving", {
        loading: true,
      });
      try {
        const inschrijvingSettings =
          await InschrijvingenService.getInschrijvingStatus();

        if (inschrijvingSettings.status === "Gereserveerd") {
          let deelnemers;
          let deelnemerIndex = -1;
          deelnemers = new Array(inschrijvingSettings.aantalDeelnemers)
            .fill({})
            .map(() => getDefaultValuesDeelnemer());
          if (inschrijvingSettings.groepId) {
            inschrijvingSettings.groep = await InschrijvingenService.getGroep(
              inschrijvingSettings.groepId
            );
            let groepDeelnemers = await InschrijvingenService.getDeelnemers(
              inschrijvingSettings.groepId
            );
            groepDeelnemers = groepDeelnemers.sort((a) =>
              a.email === inschrijvingSettings.eigenaarEmail ? -1 : 0
            );
            if (groepDeelnemers.length > 0) {
              for (var i = 0; i < groepDeelnemers.length; i++) {
                deelnemers[i] = groepDeelnemers[i];
                deelnemerIndex = i + 1;
              }
            } else {
              deelnemers[0].email = inschrijvingSettings.eigenaarEmail;
              deelnemerIndex = 0;
            }
          } else {
            deelnemers[0].email = inschrijvingSettings.eigenaarEmail;
            deelnemerIndex = -1;
          }
          inschrijvingSettings.deelnemers = deelnemers;
          inschrijvingSettings.deelnemerIndex = deelnemerIndex;
        } else if (inschrijvingSettings.status === "InTeam") {
          if (inschrijvingSettings.aantalDeelnemers) {
            inschrijvingSettings.groep = await InschrijvingenService.getGroep(
              inschrijvingSettings.groepId
            );
          }
          inschrijvingSettings.deelnemer =
            await InschrijvingenService.getDeelnemer(
              inschrijvingSettings.groepId,
              inschrijvingSettings.deelnemerId
            );
        }

        commit("updateInschrijving", inschrijvingSettings);
      } finally {
        commit("updateInschrijving", {
          loading: false,
        });
      }
    },
    async plaatsReservering({ commit }, reservering) {
      commit("updateInschrijving", {
        loading: true,
        errorMessage: undefined,
      });
      try {
        const result = await InschrijvingenService.createReservering(
          reservering
        );

        var deelnemers = new Array(result.aantalDeelnemers)
          .fill({})
          .map(() => getDefaultValuesDeelnemer());
        deelnemers[0].email = result.eigenaarEmail;

        const newInschrijving = {
          status: result.opWachtrij ? "OpWachtlijst" : "Gereserveerd",
          binnenSlapen: result.binnenSlapen,
          akkoord:
            reservering.binnenSlapen === result.binnenSlapen &&
            !result.opWachtrij,
          aantalDeelnemers: result.aantalDeelnemers,
          type: result.type,
          eigenaarEmail: result.eigenaarEmail,
          deelnemers,
          deelnemerIndex: -1,
        };

        commit("updateInschrijving", newInschrijving);
      } catch (e) {
        commit("updateInschrijving", {
          errorMessage: e.response.data,
        });
      } finally {
        commit("updateInschrijving", {
          loading: false,
        });
      }
    },
    async updateGroep({ commit, state }, groep) {
      commit("updateInschrijving", {
        loading: true,
        errorMessage: undefined,
      });
      try {
        if (state.inschrijving.groepId) {
          await InschrijvingenService.updateGroep(
            state.inschrijving.groepId,
            groep
          );
          commit("updateInschrijving", {
            deelnemerIndex: 0,
          });
        } else {
          const result = await InschrijvingenService.createGroep(groep);

          const newInschrijving = {
            groepId: result.groepId,
            groep,
            deelnemerIndex: 0,
          };

          commit("updateInschrijving", newInschrijving);
        }
      } catch (e) {
        commit("updateInschrijving", {
          errorMessage: e.response.data,
        });
      } finally {
        commit("updateInschrijving", {
          loading: false,
        });
      }
    },
    async updateDeelnemer({ commit, state }, { deelnemerIndex, deelnemer }) {
      commit("updateInschrijving", {
        loading: true,
        errorMessage: undefined,
      });
      try {
        var deelnemers = state.inschrijving.deelnemers;
        if (deelnemers[deelnemerIndex].id) {
          await InschrijvingenService.updateDeelnemer(
            state.inschrijving.groepId,
            state.inschrijving.deelnemers[deelnemerIndex].id,
            deelnemer
          );

          deelnemers[deelnemerIndex] = deelnemer;
          commit("updateInschrijving", {
            deelnemers,
            deelnemerIndex: deelnemerIndex + 1,
          });
        } else {
          const result = await InschrijvingenService.createDeelnemer(
            state.inschrijving.groepId,
            deelnemer
          );

          deelnemers[deelnemerIndex] = deelnemer;
          deelnemers[deelnemerIndex].id = result.id;
          const newInschrijving = {
            deelnemers: deelnemers,
            deelnemerIndex: deelnemerIndex + 1,
          };

          commit("updateInschrijving", newInschrijving);
        }
      } catch (e) {
        commit("updateInschrijving", {
          errorMessage: e.response.data,
        });
      } finally {
        commit("updateInschrijving", {
          loading: false,
        });
      }
    },
    async inschrijvingAfronden({ commit, state }) {
      commit("updateInschrijving", {
        loading: true,
        errorMessage: undefined,
      });
      try {
        await InschrijvingenService.inschrijvingAfronden(
          state.inschrijving.groepId
        );

        console.log("inschrijving", { ...state.inschrijving.deelnemers[0] });
        const newInschrijving = {
          deelnemers: undefined,
          deelnemer: { ...state.inschrijving.deelnemers[0] },
          deelnemerIndex: undefined,
          status: "InTeam",
        };

        commit("updateInschrijving", newInschrijving);
      } catch (e) {
        commit("updateInschrijving", {
          errorMessage: e.response.data,
        });
      } finally {
        commit("updateInschrijving", {
          loading: false,
        });
      }
    },
    async updateInTeamGroep({ commit, state }, groep) {
      commit("updateInschrijving", {
        loading: true,
        errorMessage: undefined,
      });
      try {
        await InschrijvingenService.updateGroep(
          state.inschrijving.groepId,
          groep
        );
      } catch (e) {
        commit("updateInschrijving", {
          errorMessage: e.response.data,
        });
      } finally {
        commit("updateInschrijving", {
          loading: false,
        });
      }
    },
    async updateInTeamDeelnemer({ commit, state }, deelnemer) {
      commit("updateInschrijving", {
        loading: true,
        errorMessage: undefined,
      });
      try {
        await InschrijvingenService.updateDeelnemer(
          state.inschrijving.groepId,
          state.inschrijving.deelnemerId,
          deelnemer
        );
      } catch (e) {
        commit("updateInschrijving", {
          errorMessage: e.response.data,
        });
      } finally {
        commit("updateInschrijving", {
          loading: false,
        });
      }
    },
  },
});

export default store;
