<template>
  <div id="app">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="./assets/logo.png" />
          <b>Hike and Seek</b>
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          Home
        </b-navbar-item>
        <b-navbar-item
          v-if="isOpenInschrijving"
          tag="router-link"
          :to="{ path: '/Inschrijving' }"
          >Inschrijving
        </b-navbar-item>
        <b-navbar-dropdown label="Uitslag">
          <b-navbar-item tag="router-link" :to="{ path: '/scores/2022' }">
            2022
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/scores/2023' }">
            2023
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/scores/2024' }">
            2024
          </b-navbar-item>
        </b-navbar-dropdown>
        <!-- <b-navbar-item tag="router-link" :to="{ path: '/spel' }">
          Spel
        </b-navbar-item>-->
        <b-navbar-dropdown label="Hoofdkwartier" v-if="showHoofdkwartier">
          <b-navbar-item
            v-if="showHoofdkwartierGroepen"
            tag="router-link"
            :to="{ path: '/hoofdkwartier/groepen' }"
          >
            Groepen
          </b-navbar-item>
          <b-navbar-item
            v-if="showHoofdkwartierPosten"
            tag="router-link"
            :to="{ path: '/hoofdkwartier/posten' }"
          >
            Posten
          </b-navbar-item>
          <b-navbar-item
            v-if="showHoofdkwartierRadar"
            tag="router-link"
            :to="{ path: '/hoofdkwartier/radar' }"
          >
            Radar
          </b-navbar-item>
          <b-navbar-item
            v-if="showHoofdkwartierRadar"
            tag="router-link"
            :to="{ path: '/hoofdkwartier/bosgebieden' }"
          >
            Bosgebieden
          </b-navbar-item>
          <b-navbar-item
            v-if="showHoofdkwartierSeekerRadar"
            tag="router-link"
            :to="{ path: '/hoofdkwartier/seekerradar' }"
          >
            Seeker radar
          </b-navbar-item>
          <b-navbar-item
            v-if="showHoofdkwartierReporting"
            tag="router-link"
            :to="{ path: '/hoofdkwartier/reporting' }"
          >
            Reporting
          </b-navbar-item>
          <b-navbar-item
            v-if="showHoofdkwartierScanner"
            tag="router-link"
            :to="{ path: '/hoofdkwartier/scanner' }"
          >
            Barcode scanner
          </b-navbar-item>
          <b-navbar-item
            v-if="showHoofdkwartierScores"
            tag="router-link"
            :to="{ path: '/hoofdkwartier/scores' }"
          >
            Scores
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-dropdown label="Beheer" v-if="showBeheer">
          <b-navbar-item
            v-if="showBeheerReserveringen"
            tag="router-link"
            :to="{ path: '/beheer/reserveringen' }"
          >
            Reserveringen
          </b-navbar-item>
          <b-navbar-item
            v-if="showBeheerGroepen"
            tag="router-link"
            :to="{ path: '/beheer/groepen' }"
          >
            Groepen
          </b-navbar-item>
          <b-navbar-item
            v-if="showBeheerPosten"
            tag="router-link"
            :to="{ path: '/beheer/posten' }"
          >
            Posten
          </b-navbar-item>
          <b-navbar-item
            v-if="showBeheerSettings"
            tag="router-link"
            :to="{ path: '/beheer/settings' }"
          >
            Settings
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div v-if="!isIngelogd" class="buttons">
            <a href="/identity/account/register" class="button is-primary">
              <strong>Registreren</strong>
            </a>
            <a href="/identity/account/login" class="button is-light">
              Inloggen
            </a>
          </div>
          <div v-if="isIngelogd" class="buttons">
            <a href="/identity/account/Logout" class="button is-light">
              Logout
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <router-view />
  </div>
</template>

<style lang="scss"></style>
<script>
import BNavbar from "buefy/src/components/navbar/Navbar.vue";
import BNavbarItem from "buefy/src/components/navbar/NavbarItem.vue";
import BNavbarDropdown from "buefy/src/components/navbar/NavbarDropdown.vue";

export default {
  components: {
    BNavbar,
    BNavbarItem,
    BNavbarDropdown,
  },
  computed: {
    isIngelogd() {
      return this.$store.state.userInfo.isLoggedIn;
    },
    isOpenInschrijving() {
      return this.$store.state.userInfo.openInschrijving;
    },
    showBeheer() {
      return (
        this.showBeheerGroepen ||
        this.showBeheerPosten ||
        this.showBeheerSettings ||
        this.showBeheerReserveringen
      );
    },
    showHoofdkwartier() {
      return (
        this.showHoofdkwartierGroepen ||
        this.showHoofdkwartierPosten ||
        this.showHoofdkwartierScanner ||
        this.showHoofdkwartierRadar ||
        this.showHoofdkwartierSeekerRadar ||
        this.showHoofdkwartierReporting ||
        this.showHoofdkwartierScores
      );
    },
    showBeheerGroepen() {
      return (
        this.heeftToegangTot("EditGroeps") &&
        !this.$store.state.userInfo.openInschrijving
      );
    },
    showBeheerPosten() {
      return this.heeftToegangTot("ManagePosten");
    },
    showBeheerSettings() {
      return this.heeftToegangTot("ManageSettings");
    },
    showBeheerReserveringen() {
      return (
        this.$store.state.userInfo.openInschrijving &&
        this.heeftToegangTot("EditGroeps")
      );
    },
    showHoofdkwartierGroepen() {
      return this.heeftToegangTot("ViewSpelAssets");
    },
    showHoofdkwartierPosten() {
      return this.heeftToegangTot("ViewSpelAssets");
    },
    showHoofdkwartierScanner() {
      return this.heeftToegangTot("ViewSpelAssets");
    },
    showHoofdkwartierRadar() {
      return this.heeftToegangTot("ViewSpelAssets");
    },
    showHoofdkwartierSeekerRadar() {
      return (
        this.heeftToegangTot("ViewSpelAssets") ||
        this.heeftToegangTot("ViewRadar")
      );
    },
    showHoofdkwartierReporting() {
      return this.heeftToegangTot("ViewSpelAssets");
    },
    showHoofdkwartierScores() {
      return this.heeftToegangTot("ViewHighscore");
    },
  },
  methods: {
    heeftToegangTot(value) {
      return this.$store.state.userInfo.toegangTot.includes(value);
    },
  },
};
</script>
