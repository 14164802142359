import rdToWgs84 from "./rd-to-wgs84";
export function messageNaarType(message) {
  if (message) {
    return "is-danger";
  }
  return undefined;
}

export function rijksDriehoekToCoords(x, y) {
  const result = rdToWgs84(x, y);
  return { lat: result.lat, lng: result.lon };
}



export function getVerbodenZones(){
  return [
    {name: 'De Utrecht/n(verboden zone)', color: 'red',	latlngs: [[51.4217,5.1764],[51.4206,5.1752],[51.4208,5.1748],[51.4157,5.1686],[51.4162,5.16],[51.4199,5.1598],[51.419,5.1554],[51.4201,5.1544],[51.422,5.1568],[51.4226,5.158],[51.4223,5.1596],[51.427,5.1591],[51.4275,5.1589],[51.4272,5.1543],[51.4282,5.1543],[51.4283,5.1548],[51.4288,5.1549],[51.4293,5.1531],[51.4289,5.1526],[51.4287,5.1513],[51.4286,5.1419],[51.4323,5.1419],[51.4469,5.1411],[51.4492,5.1411],[51.4493,5.1417],[51.4458,5.1451],[51.4462,5.1485],[51.4461,5.1521],[51.4472,5.1521],[51.4479,5.1517],[51.4493,5.1543],[51.4504,5.1538],[51.4511,5.153],[51.4523,5.1552],[51.4526,5.1556],[51.4528,5.1556],[51.4528,5.1573],[51.4546,5.1578],[51.4549,5.1576],[51.4563,5.1574],[51.4569,5.1607],[51.4489,5.1671],[51.4475,5.1684],[51.4484,5.1712],[51.4471,5.1722],[51.4477,5.1746],[51.4464,5.1738],[51.4459,5.1743],[51.4457,5.1754],[51.4452,5.1756],[51.4448,5.1763],[51.444,5.1759],[51.441,5.1762],[51.4402,5.18],[51.4388,5.1776],[51.4304,5.1904],[51.4308,5.1936],[51.4218,5.1937],[51.4217,5.1764]]},
    {name: 'De Utrecht/n(verboden zone)', color: 'red',	latlngs: [[51.4236,5.1411],[51.4226,5.1383],[51.4217,5.1369],[51.4216,5.1358],[51.4205,5.1321],[51.4242,5.131],[51.4282,5.1301],[51.4344,5.1296],[51.4385,5.1298],[51.4426,5.1304],[51.4466,5.1314],[51.4493,5.1323],[51.4466,5.1337],[51.4482,5.1404],[51.4308,5.1412],[51.4236,5.1411]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4896,5.2018],[51.4918,5.2038],[51.4917,5.2041],[51.4922,5.2047],[51.4918,5.2053],[51.4875,5.2088],[51.4881,5.2108],[51.4863,5.2123],[51.4872,5.2159],[51.4822,5.2145],[51.4816,5.219],[51.4729,5.2163],[51.4728,5.2127],[51.4734,5.2089],[51.4767,5.2098],[51.4777,5.2033],[51.4839,5.2026],[51.4839,5.2021],[51.4852,5.2015],[51.4856,5.2037],[51.4896,5.2018]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4736,5.189],[51.4722,5.1885],[51.4721,5.1888],[51.4714,5.1885],[51.4699,5.1872],[51.4699,5.1868],[51.4706,5.1871],[51.4718,5.188],[51.4728,5.1882],[51.4737,5.1879],[51.4736,5.189]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4663,5.1876],[51.4657,5.1868],[51.4656,5.1854],[51.4665,5.1847],[51.4671,5.184],[51.4685,5.1837],[51.4697,5.1837],[51.4707,5.1829],[51.4717,5.1817],[51.472,5.1845],[51.4719,5.1847],[51.4704,5.185],[51.4696,5.1863],[51.4694,5.1871],[51.4663,5.1876]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.456,5.1704],[51.4562,5.1716],[51.4555,5.1721],[51.4556,5.1724],[51.4551,5.1727],[51.4551,5.1737],[51.4541,5.1735],[51.4541,5.1726],[51.4535,5.1723],[51.4546,5.1709],[51.4531,5.1667],[51.4549,5.1656],[51.4552,5.1665],[51.4571,5.1657],[51.4565,5.1697],[51.456,5.1704]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.3926,5.2084],[51.3923,5.2074],[51.3922,5.2058],[51.3934,5.2057],[51.3934,5.2049],[51.3939,5.2047],[51.394,5.2038],[51.3947,5.204],[51.3952,5.2065],[51.3955,5.2068],[51.3926,5.2084]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4067,5.2355],[51.4036,5.2353],[51.4027,5.2349],[51.4019,5.2342],[51.4017,5.2328],[51.4014,5.2328],[51.4013,5.2323],[51.4041,5.2307],[51.4051,5.2297],[51.4053,5.2319],[51.405,5.2321],[51.4056,5.2334],[51.4073,5.2336],[51.4067,5.2355]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4043,5.2501],[51.4049,5.2494],[51.4059,5.2503],[51.4056,5.252],[51.4046,5.2541],[51.4037,5.2499],[51.4034,5.2492],[51.4043,5.2501]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4076,5.2648],[51.411,5.2711],[51.4134,5.2797],[51.4129,5.2792],[51.4065,5.2693],[51.4067,5.2689],[51.4057,5.2653],[51.4076,5.2648]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4228,5.2656],[51.4256,5.2703],[51.4251,5.2715],[51.4236,5.2686],[51.4219,5.2668],[51.4228,5.2656]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4517,5.3023],[51.4511,5.3048],[51.4516,5.3049],[51.4518,5.3072],[51.4499,5.3076],[51.4496,5.3052],[51.4485,5.3054],[51.4477,5.3046],[51.4489,5.3004],[51.4517,5.3023]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4629,5.2767],[51.4625,5.2772],[51.4628,5.279],[51.464,5.2803],[51.4643,5.2796],[51.4653,5.2817],[51.4673,5.2818],[51.4676,5.2839],[51.4658,5.2845],[51.4675,5.2907],[51.4679,5.2902],[51.4682,5.2913],[51.4686,5.291],[51.4687,5.2918],[51.4785,5.2804],[51.4806,5.2847],[51.4823,5.2893],[51.4827,5.2914],[51.4809,5.2946],[51.484,5.2991],[51.4827,5.3012],[51.4835,5.3028],[51.4807,5.3075],[51.4777,5.312],[51.4746,5.3162],[51.4713,5.3201],[51.4678,5.3236],[51.4639,5.3271],[51.4638,5.3259],[51.4629,5.3259],[51.4627,5.324],[51.4608,5.3203],[51.461,5.3196],[51.4608,5.3153],[51.4602,5.3141],[51.4589,5.3045],[51.4579,5.3035],[51.4584,5.3031],[51.4545,5.2982],[51.4532,5.2974],[51.4553,5.2944],[51.4541,5.2854],[51.4593,5.2767],[51.4604,5.2751],[51.4613,5.2748],[51.4629,5.2767]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4693,5.2612],[51.4709,5.261],[51.4716,5.2601],[51.4721,5.2607],[51.4729,5.2608],[51.473,5.2612],[51.4726,5.2612],[51.4722,5.2616],[51.4719,5.2613],[51.4714,5.2614],[51.4713,5.261],[51.4707,5.2614],[51.4695,5.2617],[51.4695,5.262],[51.4693,5.2612]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4416,5.2206],[51.4448,5.2251],[51.4473,5.2295],[51.4485,5.2304],[51.4506,5.2313],[51.4515,5.2321],[51.4519,5.2326],[51.4516,5.2332],[51.4564,5.2381],[51.4565,5.2391],[51.4571,5.2401],[51.4586,5.2419],[51.4579,5.2435],[51.4588,5.2433],[51.4601,5.2459],[51.4602,5.2509],[51.4581,5.2509],[51.4578,5.2537],[51.4581,5.255],[51.4578,5.2552],[51.4583,5.2575],[51.4553,5.2596],[51.448,5.2602],[51.4471,5.2589],[51.4457,5.2487],[51.4432,5.2488],[51.4379,5.2524],[51.4337,5.239],[51.4348,5.2389],[51.4348,5.2394],[51.4366,5.2378],[51.4387,5.2222],[51.4396,5.2201],[51.4397,5.2203],[51.4395,5.2209],[51.4404,5.2225],[51.4416,5.2206]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4286,5.1419],[51.4287,5.1513],[51.4289,5.1526],[51.4293,5.1531],[51.4288,5.1549],[51.4283,5.1548],[51.4282,5.1543],[51.4272,5.1543],[51.4275,5.1589],[51.427,5.1591],[51.4223,5.1596],[51.4226,5.158],[51.422,5.1568],[51.4201,5.1544],[51.4193,5.155],[51.419,5.1554],[51.419,5.1558],[51.4163,5.1573],[51.4163,5.1547],[51.4177,5.1537],[51.4192,5.1515],[51.4176,5.1512],[51.4176,5.15],[51.4181,5.15],[51.4179,5.1487],[51.4196,5.1471],[51.418,5.1449],[51.4211,5.1428],[51.4233,5.1418],[51.4286,5.1419]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4165,5.2332],[51.4155,5.2275],[51.4146,5.2271],[51.4114,5.2277],[51.4115,5.2213],[51.4112,5.221],[51.4115,5.2203],[51.4115,5.216],[51.4192,5.219],[51.4206,5.2192],[51.4216,5.2189],[51.4226,5.2183],[51.4295,5.2134],[51.4312,5.2125],[51.4329,5.2109],[51.4348,5.2102],[51.4367,5.2097],[51.4397,5.2117],[51.4409,5.213],[51.4406,5.2142],[51.4398,5.2135],[51.4379,5.2136],[51.4361,5.2132],[51.4353,5.2141],[51.4349,5.216],[51.434,5.2176],[51.4324,5.2172],[51.4306,5.2179],[51.4292,5.22],[51.4291,5.2213],[51.4282,5.2231],[51.427,5.224],[51.4262,5.2238],[51.4273,5.2286],[51.4249,5.2304],[51.4165,5.2332]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4052,5.2832],[51.4072,5.2833],[51.4072,5.2849],[51.4063,5.2847],[51.4057,5.2895],[51.4053,5.2884],[51.4046,5.289],[51.4048,5.2866],[51.4046,5.2841],[51.4052,5.2832]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4044,5.2818],[51.4063,5.2803],[51.4069,5.2826],[51.4052,5.2825],[51.4049,5.2826],[51.4046,5.283],[51.4044,5.2818]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.409,5.2571],[51.4079,5.2584],[51.4057,5.2615],[51.4052,5.2626],[51.4045,5.2574],[51.409,5.2571]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4075,5.2498],[51.4097,5.2479],[51.409,5.246],[51.4139,5.2415],[51.4152,5.2445],[51.4116,5.2493],[51.4083,5.2513],[51.4077,5.2513],[51.4075,5.2498]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4032,5.2502],[51.4042,5.2546],[51.4028,5.256],[51.4027,5.2554],[51.4008,5.2544],[51.4007,5.2514],[51.4009,5.2505],[51.4017,5.252],[51.4023,5.2511],[51.4023,5.2495],[51.4028,5.2493],[51.4032,5.2502]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4149,5.2619],[51.4153,5.2687],[51.4123,5.2708],[51.4112,5.2685],[51.4103,5.2647],[51.4111,5.2608],[51.4111,5.2593],[51.4098,5.2593],[51.4093,5.2578],[51.4096,5.2571],[51.4111,5.251],[51.4111,5.2505],[51.4121,5.2495],[51.4152,5.2453],[51.4172,5.2435],[51.419,5.2556],[51.4196,5.2543],[51.42,5.2519],[51.4209,5.2507],[51.4231,5.2549],[51.4215,5.2573],[51.423,5.2625],[51.4236,5.262],[51.4243,5.2648],[51.423,5.2647],[51.4224,5.265],[51.4219,5.2659],[51.4217,5.2656],[51.4218,5.2654],[51.4215,5.2643],[51.4215,5.2637],[51.4207,5.2626],[51.4202,5.2632],[51.4189,5.2597],[51.417,5.2601],[51.4167,5.2594],[51.4153,5.26],[51.4149,5.2619]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4258,5.2598],[51.4271,5.2571],[51.4279,5.2587],[51.4268,5.2595],[51.4271,5.261],[51.4283,5.2601],[51.4292,5.2632],[51.4266,5.2651],[51.4258,5.2598]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4389,5.2608],[51.4376,5.261],[51.4355,5.2622],[51.4374,5.2566],[51.4391,5.2541],[51.44,5.2537],[51.4408,5.2558],[51.4382,5.2581],[51.4389,5.2608]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.43,5.2666],[51.4269,5.2657],[51.4294,5.2639],[51.4301,5.2664],[51.43,5.2666]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4328,5.2568],[51.4332,5.259],[51.4318,5.2598],[51.4319,5.2606],[51.4316,5.2607],[51.4325,5.2642],[51.4308,5.2653],[51.4304,5.2658],[51.4287,5.2598],[51.4328,5.2568]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4597,5.2732],[51.4582,5.2725],[51.4576,5.2685],[51.4573,5.2684],[51.4573,5.2677],[51.4538,5.2681],[51.4531,5.2685],[51.4518,5.2674],[51.4505,5.2655],[51.4505,5.265],[51.4523,5.2654],[51.4525,5.2649],[51.4519,5.2607],[51.4538,5.2606],[51.4551,5.2624],[51.4573,5.2645],[51.4582,5.2648],[51.4593,5.2637],[51.4598,5.261],[51.4606,5.2598],[51.461,5.2605],[51.4615,5.26],[51.4615,5.2603],[51.4621,5.261],[51.4624,5.2623],[51.4607,5.2632],[51.4609,5.2642],[51.4617,5.2642],[51.4622,5.2656],[51.461,5.2663],[51.4611,5.2673],[51.4591,5.2691],[51.4604,5.2708],[51.4608,5.2727],[51.4609,5.2741],[51.4597,5.2732]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4638,5.2604],[51.4645,5.2613],[51.4656,5.2621],[51.4672,5.2611],[51.4688,5.2612],[51.4694,5.2633],[51.4669,5.2628],[51.4656,5.2646],[51.4644,5.2621],[51.4638,5.2629],[51.4634,5.262],[51.4628,5.2622],[51.4625,5.2607],[51.4638,5.2604]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4397,5.2906],[51.4478,5.2917],[51.4515,5.2895],[51.4426,5.3207],[51.4423,5.3212],[51.4413,5.3202],[51.4385,5.3226],[51.4371,5.3186],[51.4357,5.3174],[51.4328,5.3131],[51.4284,5.3045],[51.4262,5.301],[51.4231,5.2974],[51.4245,5.2969],[51.4247,5.2967],[51.426,5.299],[51.4267,5.2977],[51.4277,5.2967],[51.4305,5.299],[51.4304,5.3031],[51.4314,5.3044],[51.4337,5.2959],[51.4334,5.2928],[51.4353,5.2864],[51.4351,5.2862],[51.4385,5.2847],[51.4397,5.2906]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4324,5.2813],[51.4322,5.2824],[51.4317,5.2834],[51.4314,5.2845],[51.4314,5.2873],[51.4311,5.2875],[51.4309,5.284],[51.4306,5.284],[51.4305,5.2832],[51.4294,5.2835],[51.4291,5.2821],[51.4299,5.2796],[51.4307,5.2793],[51.4311,5.2795],[51.4314,5.2801],[51.4324,5.2813]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4123,5.2352],[51.4129,5.2396],[51.4105,5.2415],[51.4085,5.2363],[51.4098,5.2361],[51.4123,5.2352]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.3865,5.2458],[51.3861,5.2469],[51.3882,5.2514],[51.3868,5.2523],[51.3865,5.2504],[51.3847,5.2515],[51.3841,5.2482],[51.3853,5.2469],[51.3851,5.2451],[51.386,5.2447],[51.3865,5.2458]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4111,5.3004],[51.4119,5.3014],[51.4105,5.3036],[51.4098,5.3025],[51.4091,5.3039],[51.4097,5.3071],[51.4093,5.308],[51.41,5.3099],[51.4081,5.3123],[51.4053,5.3039],[51.4047,5.3007],[51.4041,5.2988],[51.404,5.2972],[51.4047,5.2963],[51.4085,5.2984],[51.4117,5.2994],[51.4111,5.3004]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4091,5.2744],[51.4114,5.2781],[51.4129,5.2802],[51.4142,5.2815],[51.4148,5.2819],[51.4159,5.2821],[51.416,5.2826],[51.4146,5.2827],[51.4144,5.283],[51.4112,5.2805],[51.4108,5.2808],[51.4076,5.28],[51.4064,5.2808],[51.4061,5.2796],[51.4081,5.2778],[51.4076,5.2762],[51.4091,5.2744]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4147,5.304],[51.415,5.3042],[51.4158,5.3037],[51.4157,5.3024],[51.4167,5.3019],[51.4169,5.3024],[51.4173,5.3024],[51.4174,5.3031],[51.4178,5.3032],[51.4185,5.303],[51.4185,5.3024],[51.4187,5.3023],[51.4188,5.3035],[51.4193,5.3035],[51.4192,5.307],[51.4193,5.3084],[51.4171,5.3112],[51.4173,5.3122],[51.4171,5.3127],[51.4159,5.3127],[51.4159,5.3132],[51.4142,5.3125],[51.414,5.31],[51.4147,5.3095],[51.4145,5.308],[51.4155,5.3079],[51.4155,5.3071],[51.4142,5.307],[51.4141,5.3052],[51.4139,5.3052],[51.4147,5.304]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4974,5.2468],[51.498,5.245],[51.4993,5.2451],[51.499,5.25],[51.4982,5.2565],[51.4972,5.2628],[51.4959,5.269],[51.4892,5.2652],[51.4887,5.2621],[51.4877,5.2596],[51.4876,5.2584],[51.4879,5.2568],[51.488,5.2531],[51.4882,5.2523],[51.4894,5.2514],[51.4926,5.2464],[51.4974,5.2468]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4529,5.2089],[51.4505,5.2109],[51.4497,5.2142],[51.4487,5.2134],[51.445,5.2117],[51.4449,5.2115],[51.4483,5.2059],[51.4484,5.206],[51.4507,5.2019],[51.4521,5.2026],[51.4529,5.2089]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4915,5.2466],[51.4864,5.2422],[51.4859,5.2422],[51.4833,5.241],[51.485,5.238],[51.4848,5.2399],[51.4867,5.2406],[51.4869,5.2423],[51.4885,5.242],[51.4884,5.2408],[51.4898,5.2406],[51.49,5.2419],[51.4911,5.2417],[51.4915,5.2466]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4875,5.2558],[51.4848,5.2542],[51.4832,5.2553],[51.4814,5.2531],[51.48,5.2527],[51.4798,5.2521],[51.4795,5.2519],[51.4785,5.252],[51.4788,5.2493],[51.4795,5.2479],[51.4812,5.2462],[51.482,5.2412],[51.4858,5.2429],[51.4862,5.2428],[51.4914,5.2473],[51.4891,5.2508],[51.488,5.2517],[51.4877,5.2522],[51.4875,5.2529],[51.4875,5.2558]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4798,5.2586],[51.4848,5.2549],[51.4875,5.2566],[51.4871,5.2584],[51.4873,5.2598],[51.4881,5.2617],[51.4885,5.2637],[51.4875,5.264],[51.4873,5.2631],[51.485,5.2638],[51.4839,5.2631],[51.483,5.2609],[51.4822,5.2615],[51.4799,5.2605],[51.4798,5.2586]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4431,5.1944],[51.4454,5.1935],[51.4458,5.1938],[51.4471,5.1957],[51.447,5.1964],[51.4431,5.1944]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4327,5.2866],[51.4326,5.286],[51.4328,5.2848],[51.4337,5.2835],[51.4346,5.2828],[51.4372,5.2845],[51.4327,5.2866]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4152,5.3294],[51.417,5.3273],[51.4199,5.3281],[51.4201,5.3279],[51.4205,5.3241],[51.4203,5.3204],[51.4228,5.3099],[51.4229,5.3076],[51.4223,5.3032],[51.4219,5.3021],[51.4228,5.2979],[51.4259,5.3015],[51.428,5.305],[51.4325,5.3136],[51.4353,5.3177],[51.4368,5.3191],[51.4385,5.3242],[51.426,5.3372],[51.4204,5.3357],[51.4159,5.3338],[51.4152,5.3294]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.3721,5.2431],[51.3705,5.2359],[51.3716,5.2366],[51.3725,5.2365],[51.3736,5.2417],[51.3721,5.2431]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.3788,5.2638],[51.3784,5.2603],[51.3785,5.2576],[51.3797,5.2575],[51.3813,5.257],[51.3832,5.257],[51.3833,5.2593],[51.3831,5.2632],[51.3788,5.2638]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.3696,5.2414],[51.3705,5.241],[51.3703,5.2401],[51.3709,5.2398],[51.3715,5.2428],[51.3709,5.243],[51.371,5.2434],[51.3697,5.2442],[51.3696,5.2414]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4218,5.1937],[51.4308,5.1936],[51.431,5.1953],[51.4315,5.1967],[51.4361,5.1993],[51.4376,5.1971],[51.4391,5.1976],[51.4385,5.2005],[51.4373,5.1999],[51.4368,5.2023],[51.4388,5.2034],[51.44,5.2021],[51.4402,5.2012],[51.4417,5.202],[51.442,5.2019],[51.4425,5.1995],[51.443,5.1996],[51.4434,5.1978],[51.4435,5.1981],[51.4439,5.1956],[51.4426,5.1955],[51.4427,5.195],[51.4447,5.196],[51.4442,5.1984],[51.4502,5.2016],[51.446,5.2088],[51.4445,5.2109],[51.4441,5.209],[51.4429,5.21],[51.4427,5.2086],[51.4434,5.2085],[51.4422,5.206],[51.4398,5.2072],[51.4392,5.2055],[51.4359,5.2083],[51.4365,5.209],[51.4327,5.2102],[51.431,5.2119],[51.4294,5.2128],[51.4214,5.2182],[51.4199,5.2185],[51.4123,5.2155],[51.4146,5.2146],[51.4187,5.2164],[51.4184,5.2099],[51.4207,5.2077],[51.4196,5.2069],[51.4212,5.2054],[51.4213,5.205],[51.4179,5.202],[51.4169,5.1994],[51.4145,5.1979],[51.4167,5.1952],[51.4196,5.1991],[51.4216,5.1954],[51.4218,5.1937]]},
    {name: 'nacht', color: 'blue',latlngs: [[51.4205,5.1321],[51.4216,5.1358],[51.4217,5.1369],[51.4168,5.1336],[51.4205,5.1321]]}
  ];
}