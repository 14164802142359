import httpclient from "./httpclient";

export async function getInschrijvingStatus() {
  var response = await httpclient.get(`/api/inschrijving`);
  return response.data;
}
export async function createReservering(reservering) {
  var response = await httpclient.post(
    "/api/inschrijving/reservering",
    reservering
  );
  return response.data;
}

export async function createGroep(groep) {
  var response = await httpclient.post("/api/inschrijving/groepen", groep);
  return response.data;
}

export async function getGroep(id) {
  var response = await httpclient.get(`/api/inschrijving/groepen/${id}`);
  return response.data;
}
export async function updateGroep(id, groep) {
  var response = await httpclient.post(
    `/api/inschrijving/groepen/${id}`,
    groep
  );
  return response.data;
}

export async function getDeelnemers(groepId) {
  var response = await httpclient.get(
    `/api/inschrijving/groepen/${groepId}/deelnemers`
  );
  return response.data;
}

export async function createDeelnemer(id, deelnemer) {
  var response = await httpclient.post(
    `/api/inschrijving/groepen/${id}/deelnemers`,
    deelnemer
  );
  return response.data;
}

export async function getDeelnemer(groepId, userId) {
  var response = await httpclient.get(
    `/api/inschrijving/groepen/${groepId}/deelnemers/${userId}`
  );
  return response.data;
}

export async function updateDeelnemer(groepId, userId, deelnemer) {
  var response = await httpclient.post(
    `/api/inschrijving/groepen/${groepId}/deelnemers/${userId}`,
    deelnemer
  );
  return response.data;
}

export async function inschrijvingAfronden(groepId) {
  var response = await httpclient.post(
    `/api/inschrijving/groepen/${groepId}/afronden`
  );
  return response.data;
}

export async function verplaatsDeelnemers(groepId, verplaatsingen) {
  var response = await httpclient.post(
    `/api/inschrijving/groepen/${groepId}/verplaatsDeelnemers`,
    verplaatsingen
  );
  return response.data;
}
