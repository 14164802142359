<template>
  <div>
    <b-message v-if="inschrijving.errorMessage" type="is-warning">
      {{ inschrijving.errorMessage }}
    </b-message>

    <b-field
      label="Voornaam"
      :type="messageNaarType(voornaamMessage)"
      :message="voornaamMessage"
    >
      <b-input v-model="deelnemer.voornaam" @blur="() => (voornaamHit = true)">
      </b-input>
    </b-field>

    <b-field
      label="Tussenvoegsels"
      :type="messageNaarType(tussenvoegselsMessage)"
      :message="tussenvoegselsMessage"
    >
      <b-input
        v-model="deelnemer.tussenvoegsels"
        @blur="() => (tussenvoegselsHit = true)"
      >
      </b-input>
    </b-field>

    <b-field
      label="Achternaam"
      :type="messageNaarType(achternaamMessage)"
      :message="achternaamMessage"
    >
      <b-input
        v-model="deelnemer.achternaam"
        @blur="() => (achternaamHit = true)"
      >
      </b-input>
    </b-field>

    <b-field
      label="Geboortedatum"
      :type="messageNaarType(geboortedatumMessage)"
      :message="geboortedatumMessage"
    >
      <b-datepicker
        v-model="deelnemer.geboortedatum"
        @blur="() => (geboortedatumHit = true)"
        :date-formatter="formatDate"
      >
      </b-datepicker>
    </b-field>

    <b-field
      label="Emailadres"
      :type="messageNaarType(emailMessage)"
      :message="emailMessage"
    >
      <b-input
        v-model="deelnemer.email"
        :disabled="emailDisabled"
        @blur="() => (emailHit = true)"
      >
      </b-input>
    </b-field>

    <b-field
      label="ScoutId"
      :type="messageNaarType(scoutIdMessage)"
      :message="scoutIdMessage"
    >
      <b-input v-model="deelnemer.scoutId" @blur="() => (scoutIdHit = true)">
      </b-input>
    </b-field>

    <b-field
      label="Mobielnummer"
      :type="messageNaarType(telefoonnummerMessage)"
      :message="telefoonnummerMessage"
    >
      <b-input
        v-model="deelnemer.telefoonnummer"
        @blur="() => (telefoonnummerHit = true)"
      >
      </b-input>
    </b-field>

    <b-field label="Eet mee">
      <b-checkbox v-model="deelnemer.eetMee"> </b-checkbox>
    </b-field>

    <b-field label="Vegetarisch" v-show="deelnemer.eetMee">
      <b-checkbox v-model="deelnemer.vegatarisch"> </b-checkbox>
    </b-field>

    <b-field
      label="Shirt"
      :type="messageNaarType(shirtMessage)"
      :message="shirtMessage"
    >
      <b-select
        v-model="deelnemer.shirt"
        :disabled="financieleVeldenDisabled"
        @blur="() => (shirtHit = true)"
        expanded
      >
        <option value="None">Geen</option>
        <option value="S">S</option>
        <option value="M">M</option>
        <option value="L">L</option>
        <option value="XL">XL</option>
        <option value="XXL">XXL</option>
        <option value="DamesS">Dames S</option>
        <option value="DamesM">Dames M</option>
        <option value="DamesL">Dames L</option>
      </b-select>
    </b-field>
    <b-field
      label="Barkaarten"
      :type="messageNaarType(barkaartenMessage)"
      :message="barkaartenMessage"
    >
      <b-input
        type="number"
        v-model="deelnemer.barkaarten"
        :disabled="financieleVeldenDisabled"
        @blur="() => (barkaartenHit = true)"
      >
      </b-input>
    </b-field>

    <div class="button__holder">
      <b-button type="is-primary" @click="opslaan">Opslaan</b-button>
    </div>
  </div>
</template>
<script>
import { BMessage } from "buefy/src/components/message";
import { BField } from "buefy/src/components/field";
import { BInput } from "buefy/src/components/input";
import { BButton } from "buefy/src/components/button";
import { BDatepicker } from "buefy/src/components/datepicker";

import { messageNaarType } from "@/utils/helpers";
import { formatDate } from "@/utils/formatters";

export default {
  components: {
    BMessage,
    BField,
    BInput,
    BButton,
    BDatepicker,
  },
  props: {
    value: {
      required: false,
      default: () => ({
        voornaam: "",
        tussenvoegsels: "",
        achternaam: "",
        geboortedatum: undefined,
        email: "",
        scoutId: "",
        telefoonnummer: "",
        eetMee: false,
        vegatarisch: false,
        shirt: "None",
        barkaarten: 0,
      }),
    },
    emailDisabled: {
      type: Boolean,
      default: false,
    },
    financieleVeldenDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validationHit: false,
      voornaamHit: false,
      tussenvoegselHit: false,
      achternaamHit: false,
      geboortedatumHit: false,
      scoutIdHit: false,
      emailHit: false,
      telefoonnummerHit: false,
      barkaartenHit: false,
      deelnemer: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.deelnemer = {
          ...newValue,
          geboortedatum: newValue.geboortedatum
            ? new Date(newValue.geboortedatum)
            : undefined,
        };
      },
    },
  },
  computed: {
    inschrijving() {
      return this.$store.state.inschrijving;
    },
    voornaamMessage() {
      if (
        (this.validationHit || this.voornaamHit) &&
        this.deelnemer.voornaam.trim().length === 0
      ) {
        return "Voornaam mag niet leeg zijn";
      }
      return undefined;
    },
    tussenvoegselsMessage() {
      return undefined;
    },
    achternaamMessage() {
      if (
        (this.validationHit || this.wachtwoordHit) &&
        this.deelnemer.achternaam.trim().length === 0
      ) {
        return "Achternaam mag niet leeg zijn";
      }
      return undefined;
    },

    geboortedatumMessage() {
      if (
        (this.validationHit || this.geboortedatumHit) &&
        !this.deelnemer.geboortedatum
      ) {
        return "Geboortedatum is verplicht";
      }
      return undefined;
    },
    emailMessage() {
      if (
        (this.validationHit || this.emailHit) &&
        !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.exec(
          this.deelnemer.email
        )
      ) {
        return "Dit is geen geldig email adres";
      }
      return undefined;
    },
    scoutIdMessage() {
      if (
        (this.validationHit || this.scoutIdHit) &&
        this.deelnemer.scoutId.trim().length === 0
      ) {
        return "Scout id mag niet leeg zijn";
      }
      return undefined;
    },
    telefoonnummerMessage() {
      if (
        (this.validationHit || this.telefoonnummerHit) &&
        !/^0[6]{1}[^0\D]{1}\d{7}$$/.exec(this.deelnemer.telefoonnummer)
      ) {
        return "Dit is geen geldig mobielnummer";
      }
      return undefined;
    },
    barkaartenMessage() {
      if (
        (this.validationHit || this.barkaartenHit) &&
        (this.deelnemer.barkaarten < 0 || this.deelnemer.barkaarten > 50)
      ) {
        return "Je kunt niet minder dan 0 of meer dan 50 barkaarten per persoon bestellen";
      }
      return undefined;
    },
    shirtMessage() {
      return undefined;
    },
  },
  methods: {
    messageNaarType,
    formatDate,
    opslaan() {
      this.validationHit = true;

      if (
        this.voornaamMessage ||
        this.achternaamMessage ||
        this.geboortedatumMessage ||
        this.emailMessage ||
        this.scoutIdMessage ||
        this.telefoonnummerMessage ||
        this.barkaartenMessage ||
        this.shirtMessage
      ) {
        return;
      }

      const offset = this.deelnemer.geboortedatum.getTimezoneOffset();
      const geboortedatum = new Date(
        this.deelnemer.geboortedatum.getTime() - offset * 60 * 1000
      );

      this.$emit("input", {
        ...this.deelnemer,
        geboortedatum: geboortedatum.toISOString().split("T")[0],
      });
    },
  },
};
</script>
